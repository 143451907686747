import { createSlice } from '@reduxjs/toolkit';
//
import * as tracker from '~store/tracker/thunks';
import { getListChildren } from '~global/helpers';

const profile = {
  children: [],
  current_mother_status: '',
};
const list = [
  {
    _id: '',
    phase_of_mother: 'no', // default
    scene: '',
    tab_index: 0,
  },
];
const tabs = [
  {
    _id: '',
    key: '',
    title: '',
    name: '',
    fetus: {},
    description: '',
    mom_todo_list: [],
    dad_todo_list: [],
  },
];
const status = {
  profile: 'idle',
  tabs: 'idle',
  tabIndex: 'idle',
  updateChild: 'idle',
  todolist: 'idle',
  nutritions: 'idle',
  nutritionHeading: 'idle',
  nutritionDetail: 'idle',
  products: 'idle',
  faqs: 'idle',
  featuredFaqs: 'idle',
  faqDetail: 'idle',
  sharedFaq: 'idle',
  listSharedFaq: 'idle',
  updateUserPoints: 'idle',
};

export const { actions, reducer } = createSlice({
  name: 'tracker',
  initialState: {
    profile,
    listIndex: 0,
    list, // list children
    tabs, // tracker tabs
    nutritions: [],
    nutritionHeading: [],
    nutritionDetail: { recommendation: [] },
    products: [],
    faqs: [],
    featuredFaqs: {},
    faqDetail: {},
    isTrack: false,
    status,
    listSharedFaq: [],
  },
  reducers: {
    setCurrentList: (state, { payload: [key, value] }) => {
      state.profile.children[state.listIndex][key] = value;
      state.list[state.listIndex][key] = value;
    },
    setState: (state, { payload: [key, value] }) => {
      state[key] = value;
    },
    setStatus: (state, { payload: [key, value] }) => {
      state.status[key] = value;
    },
    resetProfile: (state) => {
      state.profile = profile;
      state.listIndex = 0;
      state.list = list;
    },
    resetStatus: (state) => {
      state.status = status;
    },
    resetFaqDetail: (state) => {
      state.status.faqDetail = 'idle';
      state.faqDetail = {};
    },
  },
  extraReducers: {
    [tracker.getLillaProfile.pending]: (state) => {
      state.status.profile = 'pending';
    },
    [tracker.getLillaProfile.fulfilled]: (state, { payload }) => {
      state.profile = { ...profile, ...payload[0] };
      state.list =
        state.profile.children.length > 0
          ? getListChildren(state.profile.children)
          : list;
      state.status.profile = 'succeeded';
    },
    [tracker.getTrackerTabs.pending]: (state) => {
      state.status.tabs = 'pending';
    },
    [tracker.getTrackerTabs.fulfilled]: (state, { payload }) => {
      const isCurrentTrack = ({ is_current_track }) => is_current_track;
      if (payload?.length > 0) {
        const tabIndex = payload.some(isCurrentTrack)
          ? payload.findIndex(isCurrentTrack)
          : 0;

        // map key and title
        state.tabs = payload.map((item) => ({
          ...item,
          key: item._id,
          title: item.name,
        }));

        if (state.listIndex > -1) {
          state.profile.children[state.listIndex].tab_index = tabIndex;
          state.list[state.listIndex].tab_index = tabIndex;
        }
        state.status.tabs = 'succeeded';
      } else {
        state.tabs = tabs;
        state.status.tabs = 'empty';
      }
    },
    [tracker.updateTabIndex.pending]: (state) => {
      state.status.tabIndex = 'pending';
    },
    [tracker.updateTabIndex.fulfilled]: (state) => {
      state.status.tabIndex = 'succeeded';
      state.status.profile = 'idle';
    },
    [tracker.updateChild.pending]: (state) => {
      state.status.updateChild = 'pending';
    },
    [tracker.updateChild.fulfilled]: (state) => {
      state.status.updateChild = 'succeeded';
      state.status.profile = 'idle';
    },
    [tracker.updateTodolist.pending]: (state) => {
      state.status.todolist = 'pending';
    },
    [tracker.updateTodolist.fulfilled]: (state, { payload: response }) => {
      const [payload, tracker] = response;

      // profile
      state.profile = { ...profile, ...payload[0] };
      state.list =
        state.profile.children.length > 0
          ? getListChildren(state.profile.children)
          : list;

      // tracker
      state.tabs = tracker.map((item) => ({
        ...item,
        key: item._id,
        title: item.name,
      }));

      state.status.todolist = 'succeeded';
    },
    [tracker.getNutritions.pending]: (state) => {
      state.status.nutritions = 'pending';
    },
    [tracker.getNutritions.fulfilled]: (state, { payload }) => {
      state.nutritions = payload || [];
      state.status.nutritions = 'succeeded';
    },
    [tracker.getNutritionHeading.pending]: (state) => {
      state.status.nutritionHeading = 'pending';
    },
    [tracker.getNutritionHeading.fulfilled]: (state, { payload }) => {
      state.nutritionHeading = payload || [];
      state.status.nutritionHeading = 'succeeded';
    },
    [tracker.getNutritionDetail.pending]: (state) => {
      state.status.nutritionDetail = 'pending';
    },
    [tracker.getNutritionDetail.fulfilled]: (state, { payload }) => {
      state.nutritionDetail = payload || {};
      state.status.nutritionDetail = 'succeeded';
    },
    [tracker.getProductRecommendation.pending]: (state) => {
      state.status.products = 'pending';
    },
    [tracker.getProductRecommendation.fulfilled]: (state, { payload }) => {
      state.products = payload || [];
      state.status.products = payload.length > 0 ? 'succeeded' : 'empty';
    },
    [tracker.getTrackerFaqs.pending]: (state) => {
      state.status.faqs = 'pending';
    },
    [tracker.getTrackerFaqs.fulfilled]: (state, { payload }) => {
      state.faqs = Array.isArray(payload) ? payload : [];
      state.status.faqs = 'succeeded';
    },
    [tracker.getTrackerFeaturedFaqs.pending]: (state) => {
      state.status.featuredFaqs = 'pending';
    },
    [tracker.getTrackerFeaturedFaqs.fulfilled]: (state, { payload }) => {
      state.featuredFaqs = payload[0] || {};
      state.status.featuredFaqs = 'succeeded';
    },
    [tracker.getTrackerFaqDetail.pending]: (state) => {
      state.status.faqDetail = 'pending';
    },
    [tracker.getTrackerFaqDetail.fulfilled]: (state, { payload }) => {
      state.faqDetail = payload;
      state.status.faqDetail = 'succeeded';
    },
    [tracker.sharedFaq.pending]: (state) => {
      state.status.sharedFaq = 'pending';
    },
    [tracker.sharedFaq.fulfilled]: (state, { payload }) => {
      state.listSharedFaq.push(payload.data);
      state.status.sharedFaq = 'succeeded';
    },
    [tracker.getSharedFaq.pending]: (state) => {
      state.status.listSharedFaq = 'pending';
    },
    [tracker.getSharedFaq.fulfilled]: (state, { payload }) => {
      state.status.listSharedFaq = 'succeeded';
      state.listSharedFaq = payload.data;
    },
    [tracker.updateUserPoints.pending]: (state) => {
      state.status.updateUserPoints = 'pending';
    },
    [tracker.updateUserPoints.fulfilled]: (state, { payload }) => {
      state.status.updateUserPoints = 'succeeded';
    },
  },
});

export const {
  setCurrentList,
  setState,
  setStatus,
  resetProfile,
  resetStatus,
} = actions;

export default reducer;
