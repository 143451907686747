import { createSlice, createSelector } from '@reduxjs/toolkit';
import { isWeb } from '~global/screens';
const limitProduct = isWeb ? 16 : 10;
const initialState = {
  hasFiltered: false,
  onApply: false,
  onReset: false,
  brandsSelected: [],
  listBrandSelected: [],
  childAge: [
    {
      name: 'Baby/Bayi (0-12 months)',
      value: 'bayi,all-child-age',
      code: 'baby',
      isSelected: false,
    },
    {
      name: 'Infant/Batita (1-3 years)',
      value: 'batita,all-child-age',
      code: 'infant',
      isSelected: false,
    },
    {
      name: 'Toddler/Balita (3-5 years)',
      value: 'balita,all-child-age',
      code: 'toddler',
      isSelected: false,
    },
    {
      name: 'Others (5+ years)',
      value: 'child-age-others',
      code: 'others-age',
      isSelected: false,
    },
  ],
  motherhoodStage: [
    {
      name: 'Pregnancy',
      value: 'pregnant,all-motherhood-stage',
      code: 'pregnancy',
      isSelected: false,
    },
    {
      name: 'Postpartum',
      value: 'post-partum,all-motherhood-stage',
      code: 'postpartum',
      isSelected: false,
    },
    {
      name: 'Breastfeeding',
      value: 'breastfeeding,all-motherhood-stage',
      code: 'breastfeeding',
      isSelected: false,
    },
    {
      name: 'Others',
      value: 'motherhood-stage-others,mom-product',
      code: 'others',
      isSelected: false,
    },
  ],
  skinConcern: [
    {
      name: 'Normal skin',
      value: 'kulit-normal',
      code: 'normal',
      isSelected: false,
    },
    {
      name: 'Dry skin',
      value: 'kulit-kering',
      code: 'dry',
      isSelected: false,
    },
    {
      name: 'Oily skin',
      value: 'kulit-berminyak',
      code: 'oily',
      isSelected: false,
    },
    {
      name: 'Sensitive skin',
      value: 'kulit-sensitif',
      code: 'sensitive',
      isSelected: false,
    },
    {
      name: 'Stretch marks',
      value: 'stretch-mark',
      code: 'stretch',
      isSelected: false,
    },
  ],
  ratings: [
    { value: 5, isSelected: false },
    { value: 4, isSelected: false },
    { value: 3, isSelected: false },
    { value: 2, isSelected: false },
    { value: 1, isSelected: false },
  ],
  price: {
    min: 0,
    max: 1000000,
  },
  params: {
    sort: null,
    filter: {
      is_sale_lilla: null,
      'default_combination.price_after_discount': { $gte: 0, $lte: 1000000 },
      $and: [],
      'tags.name': { $in: [] },
      'brand.id': { $in: [] },
      'categories.id': { $in: null },
    },
    skip: 0,
    limit: limitProduct,
  },
  page: 1,
  filtered: {
    brandsSelected: [],
    childAge: [],
    motherhoodStage: [],
    skinConcern: [],
    ratings: [],
    price: {
      min: 0,
      max: 1000000,
    },
  },
  common: {
    goToOtherFilter: false,
  },
};

const productFiltersSlice = createSlice({
  name: 'PRODUCT_FILTERS',
  initialState,
  reducers: {
    SET_RATINGS(state, { data }) {
      state.ratings = data;
    },
    SET_SECTION(state, { data }) {
      state.section = data;
    },
    SET_PARAMS(state, { name, value }) {
      state.params[name] = value;
    },
    SET_FILTERS(state, { name, value }) {
      state.params.filter[name] = value;
    },
    SET_MOTHERHOOD_STAGE(state, { data }) {
      state.motherhoodStage = data;
    },
    SET_CHILD_AGE(state, { data }) {
      state.childAge = data;
    },
    SET_SKIN_CONCERN(state, { data }) {
      state.skinConcern = data;
    },
    SET_STATE(state, { data, stateName }) {
      state[stateName] = data;
      if (stateName === 'onApply' && data === true) {
        state.filtered = {
          brandsSelected: state.brandsSelected,
          childAge: state.childAge.filter((x) => x.isSelected),
          motherhoodStage: state.motherhoodStage.filter((x) => x.isSelected),
          skinConcern: state.skinConcern.filter((x) => x.isSelected),
          ratings: state.ratings.filter((x) => x.isSelected),
          price: {
            min: state.params.filter[
              'default_combination.price_after_discount'
            ]['$gte'],
            max: state.params.filter[
              'default_combination.price_after_discount'
            ]['$lte'],
          },
        };
      }
    },
    RESET_PARAMS(state) {
      ['childAge', 'motherhoodStage', 'skinConcern', 'ratings'].map((name) => {
        state[name].map((item, key) => {
          state[name][key].isSelected = false;
        });
      });
      state.price = {
        min: 0,
        max: 1000000,
      };
      state.params = {
        sort: null,
        filter: {
          is_sale_lilla: null,
          'default_combination.price_after_discount': {
            $gte: 0,
            $lte: 1000000,
          },
          $and: [],
          'tags.name': { $in: [] },
          'brand.id': { $in: [] },
        },
        skip: 0,
        limit: limitProduct,
      };
      state.page = 1;
      state.hasFiltered = false;
      state.listBrandSelected = [];
      state.brandsSelected = [];
      state.filtered = {
        brandsSelected: [],
        childAge: [],
        motherhoodStage: [],
        skinConcern: [],
        ratings: [],
        price: {
          min: 0,
          max: 1000000,
        },
      };
    },
    SET_COMMON(state, { name, value }) {
      state[name] = value;
    },
    setLimit(state, { value }) {
      state.params.limit = value;
    },
    setSkip(state, { value }) {
      state.params.skip = value;
    },
    setPage(state, { value }) {
      state.page = value;
    },
  },
});

export const filterChanged = createSelector(
  (state) => state.productFilters,
  (params) => {
    if (params.ratings.filter((x) => x.isSelected).length) {
      return true;
    }
    if (
      params.filtered.brandsSelected.length !== params.brandsSelected.length
    ) {
      return true;
    }
    if (
      params.filtered.price.min !==
      params.params.filter['default_combination.price_after_discount']['$gte']
    ) {
      return true;
    }
    if (
      params.filtered.price.max !==
      params.params.filter['default_combination.price_after_discount']['$lte']
    ) {
      return true;
    }
    if (
      params.filtered.childAge.length !==
      params.childAge.filter((x) => x.isSelected).length
    ) {
      return true;
    }
    if (
      params.filtered.motherhoodStage.length !==
      params.motherhoodStage.filter((x) => x.isSelected).length
    ) {
      return true;
    }
    if (
      params.filtered.skinConcern.length !==
      params.skinConcern.filter((x) => x.isSelected).length
    ) {
      return true;
    }
    return false;
  },
);

export const {
  SET_PARAMS,
  SET_SECTION,
  SET_FILTERS,
  SET_RATINGS,
  SET_MOTHERHOOD_STAGE,
} = productFiltersSlice.actions;
export default productFiltersSlice.reducer;
