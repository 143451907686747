import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '~api/search';
import { Catalog } from '~api/catalog';
import { InternalTracking } from '~global/sociolla.dm.lib';
import { isNative } from '~global/screens';
import { GoogleTagManager } from '~global/sociolla.dm.lib';
import { Soco } from '~api/soco';

export const getSearchSuggestions = createAsyncThunk(
  'search/getSearchSuggestions',
  async ({ keyword }) => await api.getSearchSuggestions({ keyword }),
);
export const getRecentlySearched = createAsyncThunk(
  'search/getRecentlySearched',
  async () => await api.getRecentlySearched(),
);
export const setHistoryClicks = createAsyncThunk(
  'search/setHistoryClicks',
  async (payload) => await api.setHistoryClicks(payload),
);
export const unsetHistoryClicks = createAsyncThunk(
  'search/unsetHistoryClicks',
  async (payload) => await api.unsetHistoryClicks(payload),
);
export const getPopularBrands = createAsyncThunk(
  'search/getPopularBrands',
  async () => await api.getPopularBrands(),
);
export const getPopularProducts = createAsyncThunk(
  'search/getPopularProducts',
  async () => await api.getPopularProducts(),
);

export const searchProducts = createAsyncThunk(
  'search/searchProducts',
  async ({ params, paramsAnalytic }) => {
    let searchResult = false;
    const response = await Soco.searchProductsRequest(params);
    if (response?.data?.data['add-review_count'] > 0) {
      searchResult = true;
    }
    const payloadSearch = {
      text: params?.filter?.keyword,
      searchResult,
      total_search_result: response?.data?.data['add-review_count'],
      recently_search_click: paramsAnalytic?.recently_search_click,
    };
    if (!isNative) {
      GoogleTagManager.search(payloadSearch);
    }
    InternalTracking.search(payloadSearch);
    return response.data;
  },
);

export const searchTotalProducts = createAsyncThunk(
  'search/searchTotalProducts',
  async (params) => {
    const response = await Catalog.searchTotalProductsRequest(params);
    return response.data;
  },
);

export const loadMoreProducts = createAsyncThunk(
  'search/loadMoreProducts',
  async (params) => {
    const response = await Soco.searchProductsRequest(params);
    return response.data;
  },
);
